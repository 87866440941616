import React from 'react';
import { useTranslation } from 'react-i18next';
import cirkitex from '../../assets/CIRKITEX.png';

const About = () => {
  const { t } = useTranslation();

  return (
    <section id="about" className="p-8 md:p-10 bg-white">
      {/* Container for the image */}
      <div className="flex justify-center mb-8">
        <img
          className="cirkitex-img w-32 h-auto md:w-40" // Responsive image size
          src={cirkitex}
          alt="logo_cirkitex"
        />
      </div>

      {/* Title */}
      <h2 className="text-2xl md:text-3xl font-bold mb-4 text-center text-black">{t('about.title')}</h2>

      {/* Text Content */}
      <div className="text-gray-700 leading-relaxed mb-4">
        <p>{t('about.content')}</p>
      </div>
      <div className="text-gray-700 leading-relaxed mb-4">
        <p>{t('about.extra_content')}</p>
      </div>
      <div className="text-gray-700 leading-relaxed">
        <p>{t('about.history')}</p>
      </div>
    </section>
  );
};

export default About;
