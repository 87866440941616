import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Select, MenuItem } from '@mui/material';
import cirkitex from '../../assets/CIRKITEX.png';

const Header = ({ onLanguageChange, language }) => {
  const { t } = useTranslation();
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <header className="bg-black p-4 text-white flex justify-between items-center shadow-md sticky top-0 z-50">
      <div className="flex items-center justify-between w-full max-w-screen-xl mx-auto">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <img
            className="logo-cirkitex w-24 h-auto md:w-32 object-contain"
            src={cirkitex}
            alt="logo_cirkitex"
          />
        </Link>

        {/* Desktop Navbar */}
        <nav className="hidden md:block md:flex-1 text-center">
          <ul className="flex space-x-8 justify-center text-xl">
            <li><Link to="/" className="hover:underline">{t('nav.home')}</Link></li>
            <li><Link to="/about" className="hover:underline">{t('nav.about')}</Link></li>
            {/* <li><Link to="/services" className="hover:underline">{t('nav.services')}</Link></li>
            <li><Link to="/products" className="hover:underline">{t('nav.products')}</Link></li> */}
            <li><Link to="/contact" className="hover:underline">{t('nav.contact')}</Link></li>
            <li><Link to="/team" className="hover:underline">{t('nav.team')}</Link></li>
          </ul>
        </nav>

        {/* Language Selector */}
        <div className="ml-4 hidden md:block">
          <Select
            value={language}
            onChange={onLanguageChange}
            variant="outlined"
            className="bg-white text-black rounded p-1 w-20 h-8"
            size="small"
          >
            <MenuItem value="en">EN</MenuItem>
            <MenuItem value="fi">FI</MenuItem>
          </Select>
        </div>

        {/* Mobile Menu Toggle (Hamburger Icon) */}
        <button
          className="md:hidden text-white focus:outline-none"
          onClick={toggleSidebar}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      {/* Mobile Sidebar */}
      <div
        className={`fixed top-0 left-0 w-64 h-full bg-black text-white z-50 transition-transform transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } md:hidden`}
      >
        <div className="flex justify-end p-4">
          <button onClick={closeSidebar} className="text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <nav className="flex flex-col items-center justify-center space-y-4">
          <li><Link to="/" className="text-lg hover:underline" onClick={closeSidebar}>{t('nav.home')}</Link></li>
          <li><Link to="/about" className="text-lg hover:underline" onClick={closeSidebar}>{t('nav.about')}</Link></li>
          {/* <li><Link to="/services" className="text-lg hover:underline" onClick={closeSidebar}>{t('nav.services')}</Link></li>
          <li><Link to="/products" className="text-lg hover:underline" onClick={closeSidebar}>{t('nav.products')}</Link></li> */}
          <li><Link to="/contact" className="text-lg hover:underline" onClick={closeSidebar}>{t('nav.contact')}</Link></li>
          <li><Link to="/team" className="text-lg hover:underline" onClick={closeSidebar}>{t('nav.team')}</Link></li>
        </nav>
        <div className="mt-8 text-center">
          <Select
            value={language}
            onChange={onLanguageChange}
            variant="outlined"
            className="bg-white text-black rounded p-1 w-20 h-8"
            size="small"
          >
            <MenuItem value="en">EN</MenuItem>
            <MenuItem value="fi">FI</MenuItem>
          </Select>
        </div>
      </div>
    </header>
  );
};

export default Header;
