// Team.js
import React from 'react';
import teamMember1 from '../../assets/team1.jpeg';
import teamMember2 from '../../assets/team2.jpeg';
import teamMember3 from '../../assets/team3.jpeg';
import teamMember4 from '../../assets/team4.jpeg';
import teamMember5 from '../../assets/team5.jpeg';
import teamMember6 from '../../assets/team6.jpeg';

const Team = () => {
  const teamMembers = [
    {
      imgSrc: teamMember1,
      name: 'Ravi Kumar',
      position: 'Co-Founder & CEO',
      description: 'Ravi leads the company with a vision for innovation and excellence in AI hardware.'
    },
    {
      imgSrc: teamMember2,
      name: 'Gaurav Singh',
      position: 'Co-Founder & CTO',
      description: 'Gaurav is the technical mastermind behind our advanced semiconductor solutions.'
    },
    {
      imgSrc: teamMember3,
      name: 'Mahesh Kumawat',
      position: 'Co-Founder & Head of Engineering',
      description: 'Mahesh oversees the hardware development process, ensuring top-notch performance engineeering.'
    },
    {
      imgSrc: teamMember4,
      name: 'Shailesh Singh Chouhan',
      position: 'Technical Advisor',
      description: 'Shailesh is responsible for driving research and innovation in AI and semiconductor technologies.'
    },
    {
      imgSrc: teamMember5,
      name: 'Omar Numan',
      position: 'Expert Advisor',
      description: 'Omar manages our expertise initiatives and works to expand our global presence.'
    },
    {
      imgSrc: teamMember6,
      name: 'Prof. Santosh Kumar Vishvakarma',
      position: 'Academic consultant',
      description: 'Santosh manages our academic initiatives and works to expand our presence.'
    }
  ];

  return (
    <section id="team" className="bg-gray-100 p-10 text-center">
      <h2 className="text-4xl font-bold mb-8 text-black">Meet Our Team</h2>
      <p className="text-lg text-gray-700 mb-10 max-w-4xl mx-auto">Our team is composed of diverse experts who share a passion for innovation and excellence. We bring together combined expertise in semiconductors, AI, and technology leadership.</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 max-w-6xl mx-auto">
        {teamMembers.map((member, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg p-6">
            <img src={member.imgSrc} alt={member.name} className="w-full h-80 object-cover rounded-t-lg mb-4" />
            <h3 className="text-2xl font-bold mb-2">{member.name}</h3>
            <p className="text-black font-semibold mb-2">{member.position}</p>
            <p className="text-gray-700 leading-relaxed">{member.description}</p>
          </div>
        ))}
      </div>
      <div className="mt-16 max-w-4xl mx-auto">
        <h3 className="text-3xl font-bold mb-4 text-black">Diversity and Community</h3>
        <p className="text-gray-700 leading-relaxed">Cirkitex's team is diverse, and we value making sure every member feels appreciated. Our varied backgrounds foster new perspectives and ideas that help us achieve our shared goals and innovate at the forefront of our industry.</p>
      </div>
    </section>
  );
};

export default Team;