
// Home.js
import React from 'react';
import semiconductorImage from '../../assets/semiconductor.webp';
import c1 from '../../assets/c1.webp';
import c2 from '../../assets/c2.webp';
import c3 from '../../assets/c3.webp';
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import { Paper, Typography } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = () => {
  const { t } = useTranslation();

  const carouselItems = [
    {
      imgSrc: c1,
      title: "Innovative AI Hardware Solutions",
      description: "Leading the industry with cutting-edge AI acceleration technology.",
    },
    {
      imgSrc: c2,
      title: "Energy Efficient Chips",
      description: "Designed for optimal performance while minimizing power consumption.",
    },
    {
      imgSrc: c3,
      title: "Custom AI Design Services",
      description: "Tailored solutions to meet the unique needs of your AI applications.",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <section id="home" className="bg-gray-100 p-10">

      <div className="text-left max-w-4xl mx-auto">
        <h3 className="text-3xl font-bold mb-4 text-black">{t('home.more_title')}</h3>
        <p className="text-gray-700 leading-relaxed mb-4">{t('home.more_content_1')}</p>
        
      <Slider {...settings} className="max-w-2xl mx-auto mb-10">
        {carouselItems.map((item, index) => (
          <div key={index}>
            <Paper elevation={3} className="p-4">
              <img
                // className="d-block w-full rounded-lg"
                className="mx-auto rounded-lg shadow-lg w-full max-w-3xl mb-10"
                src={item.imgSrc}
                alt={item.title}
              />
              <Typography variant="h5" component="div" className="mt-4">{item.title}</Typography>
              <Typography variant="body2" color="text.secondary">{item.description}</Typography>
            </Paper>
          </div>
        ))}
      </Slider>
        {/* <img src={semiconductorImage} alt="Semiconductor" className="mx-auto rounded-lg shadow-lg w-full max-w-3xl mb-10" /> */}
        <p className="text-gray-700 leading-relaxed mb-4">{t('home.more_content_2')}</p>
        <p className="text-gray-700 leading-relaxed">{t('home.more_content_3')}</p>
      </div>
    </section>
  );
};

export default Home;
